<template>
  <v-select
    hide-details
    item-text="text"
    item-value="value"
    :items="items"
    label="Wiederholung"
    :readonly="readonly"
    :value="value"
    @input="input"
  ></v-select>
</template>

<script>
import moment from "moment";
export default {
  name: "RecurrenceInput",
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    start: Date,
    value: String,
  },
  computed: {
    startWeekday() {
      const isoWeekday = moment(this.start).isoWeekday();
      switch (isoWeekday) {
        case 1:
          return "MO";
        case 2:
          return "TU";
        case 3:
          return "WE";
        case 4:
          return "TH";
        case 5:
          return "FR";
        case 6:
          return "SA";
        case 7:
          return "SU";
        default:
          return "??";
      }
    },
    items() {
      return [
        {
          text: this.$t("once"),
          value: null,
        },
        {
          text: this.$t("daily"),
          value: "FREQ=DAILY;INTERVAL=1",
        },
        {
          text: this.$t("weekly"),
          value: `FREQ=WEEKLY;BYDAY=${this.startWeekday};INTERVAL=1`,
        },
        {
          text: this.$t("biweekly"),
          value: `FREQ=WEEKLY;BYDAY=${this.startWeekday};INTERVAL=2`,
        },
      ];
    },
  },
  methods: {
    input(event) {
      this.$emit("input", event);
    },
  },
  i18n: {
    messages: {
      de: {
        once: "einmalig",
        daily: "täglich",
        weekly: "wöchentlich",
        biweekly: "zweiwöchentlich",
      },
    },
  },
};
</script>

<style scoped>
.v-input {
  margin: 0;
}
</style>